import React, { Component } from "react";
import Form from "./Form"
import Hero from "./Hero"
import Api from "./Api"
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<><Hero /><Form /></>} />
                        <Route path="/api" element={<><Api /></>} />
                    </Routes>
                </BrowserRouter>
            </>
        )
    }
}

export default App;
