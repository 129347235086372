import React, { Component } from "react";



class QuestionBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;
        const categories = data.categories.split(',');
        return (
            <div key={data.id} className="box m-5" >
                {data.imageName ?
                    <a style={{ float: 'right', textAlign: 'right' }} href={this.props.serverUrl + 'large/' + data.imageName + '.png'} rel="noreferrer" target="_blank"><img style={{ height: '100px' }} alt='Question illustration' src={this.props.serverUrl + data.imageName + '.png'} /><br /><span>{data.imageCredit}</span></a>
                    : null
                }


                {
                    categories.map(category => (
                        <span class="tag is-info is-light mr-2 mb-2">
                            {category === 'Géographie' ?
                                <span class="icon">
                                    <i class="fas fa-globe-africa"></i>
                                </span> : null
                            }
                            {category === 'Histoire' ?
                                <span class="icon">
                                    <i class="fas fa-landmark"></i>
                                </span> : null
                            }
                            {category === 'Sciences' ?
                                <span class="icon">
                                    <i class="fas fa-flask"></i>
                                </span> : null
                            }
                            {category === 'Astronomie' ?
                                <span class="icon">
                                    <i class="fas fa-moon"></i>
                                </span> : null
                            }
                            {category}
                        </span>
                    ))
                }



                <p class="title is-5" > {data.question}</p>
                <p class="subtitle is-5">
                    {data.type === 1 ?
                        <>{data.answer === 1 ? 'Vrai' : 'Faux'}</>
                        :
                        <><span className="mr-2">{data.answer}</span>
                            {data.alternativeAnswers ? <em className="has-text-grey is-size-6">({data.alternativeAnswers.replaceAll('\n', ', ')})</em> : null}
                        </>
                    }
                </p>
                <p style={{ whiteSpace: 'break-spaces' }}>{data.wrongAnswers}</p>
            </div >

        )
    }
}


export default QuestionBox;
