import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import keyword_extractor from 'keyword-extractor';

class App extends Component {
    constructor(props) {
        super(props);

        this.categoryChanged = this.categoryChanged.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.getExistingQuestions = this.getExistingQuestions.bind(this);


        this.state = {
            newCategory: true,
            status: 'writing',
            selectedCategories: [],
            existingQuestions: [],
            errors: null,
            imageError: false
        }

    }

    componentDidMount() {
        let serverUrl = 'http://localhost:8080/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:8080/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl }, this.getCategories)
    }

    getCategories() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'c', options)
            .then((response) => response.json())
            .then((json) => {
                this.setState({ categories: json })
            })
            .catch(err => console.log(err));
    }


    categoryChanged() {
        if (parseInt(document.getElementById('category').value) === 0) {
            this.setState({ newCategory: true });
        } else {
            this.setState({ newCategory: false });
        }
    }

    addCategory() {

        const category = document.getElementById('category').value;

        let categoryExist = false;
        let categoryAlreadySelected = false;




        for (let selCat of this.state.selectedCategories) {
            if (selCat.name === category) {
                categoryAlreadySelected = true;
                document.getElementById('category').value = null;
            }
        }

        if (!categoryAlreadySelected) {
            for (let cat of this.state.categories) {
                if (category === cat.name) {
                    categoryExist = true;
                    this.setState({ selectedCategories: [...this.state.selectedCategories, { id: cat.id, name: cat.name }] })
                    document.getElementById('category').value = null;
                    break;
                }
            }
            if (!categoryExist) {
                // Add a new category

                const values = {
                    category: category
                }
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                }

                fetch(this.state.serverUrl + 'c', options)
                    .then(res => res.json().then(json => {
                        if (json.message === 'success') {

                            this.setState({ categories: [...this.state.categories, { id: json.id, name: category }] },
                                this.addCategory);


                        }
                    }));
            }
        }
    }

    deleteCategory(id) {
        let newSelectedCategories = this.state.selectedCategories;

        newSelectedCategories = newSelectedCategories.filter(function (value, index, arr) {
            return value.id !== id;
        });

        this.setState({ selectedCategories: newSelectedCategories })
    }

    imageChanged(image) {

        let imageName = image.name;
        this.setState({ imageName: imageName });

        let ext = imageName.split('.')[1];
        if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'gif' && ext !== 'webp') {
            this.setState({ imageError: true })
        } else {
            this.setState({ imageError: false })
        }
    }

    getExistingQuestions() {
        const sentence = document.getElementById('question').value;
        this.setState({ existingQuestions: [] })

        var extraction_result = keyword_extractor.extract(sentence, {
            language: 'french',
            remove_digits: true,
            return_changed_case: true,
            remove_duplicates: true
        });

        let resultsString = '';
        for (let result of extraction_result) {
            resultsString += result + ',';
        }

        if (resultsString.length > 0) resultsString = resultsString.substring(0, resultsString.length - 1);

        if (extraction_result.length > 0) {
            const params = new URLSearchParams({ k: resultsString })
            const options = {
                method: 'GET'
            }

            fetch(this.state.serverUrl + 'q?' + params, options)
                .then((response) => response.json())
                .then((json) => {
                    this.setState({ existingQuestions: json })
                })
                .catch(err => console.log(err));
        }
    }

    submitForm(values, actions) {

        let errors = {};
        let selectedCategories = [];

        for (let category of this.state.selectedCategories) {
            selectedCategories.push(category.id);
        }

        values.categories = selectedCategories;

        if (values.categories.length === 0 && values.newCategory === '') {
            errors.category = 'Vous devez renseigner une catégorie.';
        }
        if (parseInt(values.type) === 0 && values.wrongAnswers.match(/((?:.*\n){2,}(.+))/gm) === null) {
            errors.answer = 'Vous devez renseigner au moins 3 mauvaises réponses.';
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            return;
        } else {

            this.setState({ errors: null, status: 'submitting', imageError: false });
        }


        const formData = new FormData();
        for (var value in values) {
            formData.append(value, values[value]);
        }

        const options = {
            method: 'POST',
            body: formData
        }

        fetch(this.state.serverUrl + 'q', options)
            .then(res => res.json().then(json => {
                if (json.message === 'success') {
                    this.setState({ status: 'writing', imageName: null })

                    actions.resetForm({
                        values: { question: '', answer: '', wrongAnswers: '', newCategory: '', type: values.type },
                    });
                    this.getCategories(); this.getExistingQuestions();
                } else if (json.message === 'error') {
                    if (json.error === 'not image') {
                        this.setState({ imageError: true, status: 'writing' })
                    }
                }
            }));
    }

    render() {

        const Categories = this.state.categories;
        const ExistingQuestions = this.state.existingQuestions;
        return (
            <Formik
                initialValues={{
                    question: '', answer: '', alternativeAnswers: '', wrongAnswers: '', newCategory: '', type: '0'
                }}

                validationSchema={
                    Yup.object({
                        question: Yup.string()
                            .min(1)
                            .required('Vous devez renseigner une question'),
                        answer: Yup.string()
                            .min(1)
                            .required('Vous devez renseigner une réponse')
                    })
                }
                onSubmit={async (values, actions) => {
                    this.submitForm(values, actions);
                }}>
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="section questionForm container" enctype="multipart/form-data">



                        {/* QUESTION */}
                        <div className="field">
                            <label for="question" className="label">Question </label>
                            <div className="control has-icons-left">
                                <Field id="question" name="question" placeholder="Question" className={errors.question && touched.question ? 'input is-danger' : 'input'} autoFocus min="1"
                                    onBlur={this.getExistingQuestions} />
                                <span class="icon is-small is-left">
                                    <i class="fas fa-question"></i>
                                </span>
                            </div>
                            <span className={errors.question && touched.question ? 'help is-danger' : 'help'}><ErrorMessage name="question" /></span>
                        </div>
                        {ExistingQuestions.length > 0 ?
                            <div className="field">
                                <span class="subtitle is-6">Questions similaires existantes :</span>
                                {
                                    ExistingQuestions.map(data => (
                                        <p key={data.id}>
                                            <span class="icon">
                                                <i class="fas fa-chevron-right"></i>
                                            </span>
                                            <span className="has-text-info has-background-info-light mr-3 is-italic">{data.question}</span>
                                            <span className="has-text-success">{data.answer}</span>
                                        </p>
                                    ))
                                }
                            </div>
                            : null}


                        {/* IMAGE */}
                        <label for="image" className="label">Image
                            <span className="help">Optionnel, uniquement si nécessaire à la questions.</span>
                        </label>
                        <div className="field has-addons is-grouped">

                            <div className="control">

                                <div class="file has-name">
                                    <label class="file-label">
                                        <input class="file-input" type="file" name="image"
                                            onChange={(event) => {
                                                setFieldValue("image", event.currentTarget.files[0]);
                                                this.imageChanged(event.currentTarget.files[0]);
                                            }}
                                        />
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload"></i>
                                            </span>
                                            <span class="file-label">
                                                Choisir une image…
                                            </span>
                                        </span>
                                        {this.state.imageName ? <span class="file-name">
                                            {this.state.imageName}
                                        </span> : null}
                                    </label>
                                </div>

                            </div>

                            {this.state.imageName ?
                                <div className="control">
                                    <div className="control has-icons-left">
                                        <Field id="credit" name="credit" className="input" placeholder="Crédit Photo" min="1" />
                                        <span class="icon is-small is-left">
                                            <i class="fab fa-creative-commons"></i>
                                        </span>
                                    </div>
                                </div> : null}
                        </div>
                        {this.state.imageError ?
                            <span className="help is-danger mb-5">Formats d'image valides : PNG, JPG, GIF, WEBP</span>
                            : null}

                        {/* CATEGORIE */}
                        <label for="category" className="label">Catégorie(s)</label>
                        <div className="field has-addons">

                            <div className="control has-icons-left" >
                                <input
                                    type="text"
                                    name="category"
                                    list="categoryList"
                                    id="category"
                                    className="input"
                                    autocomplete="off"
                                />
                                <datalist id="categoryList">
                                    {
                                        Categories !== undefined ?
                                            Categories.map(data => (
                                                <option value={data.name}>{data.name}</option>
                                            )) : null
                                    }
                                </datalist>
                                <span class="icon is-small is-left">
                                    <i class="far fa-file-alt"></i>
                                </span>
                            </div>
                            <div className="control">
                                <span className="button is-primary"
                                    onClick={() => this.addCategory(values)}
                                >Ajouter</span>
                            </div>
                        </div>
                        {this.state.errors && this.state.errors.category ? <span className="help is-danger">Vous devez renseigner au moins une catégorie.</span> : null}

                        <div className="field">
                            {
                                this.state.selectedCategories !== undefined ?
                                    this.state.selectedCategories.map(data => (
                                        <span class="tag is-info mr-2">
                                            {data.name === 'Géographie' ?
                                                <span class="icon">
                                                    <i class="fas fa-globe-africa"></i>
                                                </span> : null
                                            }
                                            {data.name === 'Histoire' ?
                                                <span class="icon">
                                                    <i class="fas fa-landmark"></i>
                                                </span> : null
                                            }
                                            {data.name === 'Sciences' ?
                                                <span class="icon">
                                                    <i class="fas fa-flask"></i>
                                                </span> : null
                                            }
                                            {data.name === 'Astronomie' ?
                                                <span class="icon">
                                                    <i class="fas fa-moon"></i>
                                                </span> : null
                                            }
                                            {data.name}
                                            <button class="delete is-small ml-2" onClick={() => { this.deleteCategory(data.id) }}></button>
                                        </span>
                                    )) : null
                            }
                        </div>


                        {/* TYPE */}
                        <div className="field">
                            <label for="type" className="label">Type</label>
                            <div role="group" className="control">
                                <label className="">
                                    <Field type="radio" name="type" value="0" className="is-hidden"
                                        checked={values.type === '0' ? 'checked' : null} />
                                    {/* <span className={values.type === '0' ? 'has-text-weight-bold has-text-link' : 'is-italic has-text-dark'}>Choix Multiple</span> */}
                                    <span class={values.type === '0' ? 'button is-info is-small mr-2 has-text-weight-bold' : 'button is-light is-small mr-2 is-italic'}>Choix Multiple</span>
                                </label>
                                <label className="">
                                    <Field type="radio" name="type" value="1" className="is-hidden" />
                                    {/* <span className={values.type === '1' ? 'has-text-weight-bold has-text-link' : 'is-italic has-text-dark'}>Vrai / Faux</span> */}
                                    <span class={values.type === '1' ? 'button is-info is-small has-text-weight-bold' : 'button is-light is-small is-italic'} >Vrai / Faux</span>

                                </label>
                            </div>
                        </div>

                        {values.type === '0' ?
                            <>
                                {/* REPONSES (MULTIPLE) */}
                                <div className="field">
                                    <label for="answer" className="label">Réponse </label>
                                    <div className="control  has-icons-left">
                                        <Field id="answer" name="answer" placeholder="Réponse" className={errors.answer && touched.answer ? 'input is-danger' : 'input'} min="1" />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-chevron-circle-right"></i>
                                        </span>
                                    </div>
                                    <span className={errors.answer && touched.answer ? 'help is-danger' : 'help'}><ErrorMessage name="answer" /></span>
                                </div>

                                <div className="field">
                                    <label for="alternativeAnswers" className="label">Réponses alternatives
                                        <span className="help">Dans le cadre d'une réponse écrite, quelles autres réponses devraient être acceptées ? Ex: Louis XIV, Louis 14, Louis Quatorze</span>
                                    </label>

                                    <div className="control">
                                        <Field id="alternativeAnswers" as="textarea" name="alternativeAnswers" placeholder="Réponses alternatives" min="1" rows="6" className="textarea" />
                                    </div>
                                </div>

                                <div className="field">
                                    <label for="wrongAnswers" className="label">Mauvaises réponses
                                        <span className="help">Au moins 3, une par ligne.</span>
                                    </label>

                                    <div className="control">
                                        <Field id="wrongAnswers" as="textarea" name="wrongAnswers" placeholder="Mauvaises réponses" className={errors.wrongAnswers && touched.wrongAnswers ? 'textarea is-danger' : 'textarea'} min="1" rows="6" />
                                    </div>
                                    <span className={errors.wrongAnswers && touched.wrongAnswers ? 'help is-danger' : 'help'}><ErrorMessage name="wrongAnswers" /></span>
                                </div>
                                {this.state.errors && this.state.errors.answer ? <span className="help is-danger mb-5">Vous devez renseigner au moins trois mauvaises réponses.</span> : null}
                            </>
                            :
                            <>
                                {/* REPONSES (VRAI / FAUX) */}
                                <div className="field mb-5">
                                    <label for="answer" className="label">Réponse</label>
                                    <div role="group" className="control">
                                        <label className="">
                                            <Field type="radio" name="answer" value="1" className="is-hidden" />
                                            <span class={values.answer === '1' ? 'button is-info mr-2 has-text-weight-bold' : 'button is-light  mr-2 is-italic'}>Vrai</span>
                                        </label>
                                        <label className="">
                                            <Field type="radio" name="answer" value="0" className="is-hidden" />
                                            <span class={values.answer === '0' ? 'button is-info has-text-weight-bold' : 'button is-light  is-italic'} >Faux</span>
                                        </label>
                                        <span className={errors.answer && touched.answer ? 'help is-danger' : 'help'}><ErrorMessage name="answer" /></span>

                                    </div>
                                </div>


                            </>

                        }

                        <button type="submit" className={this.state.status === 'submitting' ? 'button is-primary is-loading' : 'button is-primary'}>Envoyer</button>
                    </Form>
                )
                }
            </Formik>
        )
    }
}

export default App;
