import React, { Component } from "react";

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <section class="hero  is-primary">
                <div class="hero-body">
                    <p class="title">
                        Questions
                    </p>
                    <p class="subtitle">
                        Ajouter une question
                    </p>
                </div>
            </section>
        )
    }
}

export default Hero;
