import React, { Component } from "react";
import QuestionBox from "./QuestionBox"
import { Formik, Field, Form } from 'formik';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategories: [],
            prevRequest: 0,
            requestUrl: '',
            results: ''
        }
    }

    componentDidMount() {
        let serverUrl = 'http://localhost:8080/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:8080/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl }, this.getCategories)

        this.setState({ requestUrl: serverUrl + 'q?n=10' });
    }

    getCategories() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'c', options)
            .then((response) => response.json())
            .then((json) => {
                this.setState({ categories: json })
            })
            .catch(err => console.log(err));
    }

    addCategory() {
        const category = document.getElementById('category').value;

        let categoryExist = false;
        let categoryAlreadySelected = false;


        for (let selCat of this.state.selectedCategories) {
            if (selCat.name === category) {
                categoryAlreadySelected = true;
                document.getElementById('category').value = null;
            }
        }

        if (!categoryAlreadySelected) {
            for (let cat of this.state.categories) {
                if (category === cat.name) {
                    categoryExist = true;
                    this.setState({ selectedCategories: [...this.state.selectedCategories, { id: cat.id, name: cat.name }] })
                    document.getElementById('category').value = null;
                    break;
                }
            }
            if (!categoryExist) {
                // Add a new category
                return;
            }
        }
    }

    deleteCategory(id) {
        let newSelectedCategories = this.state.selectedCategories;

        newSelectedCategories = newSelectedCategories.filter(function (value, index, arr) {
            return value.id !== id;
        });

        this.setState({ selectedCategories: newSelectedCategories })
    }

    requestApi(values, selectedCategories) {
        if (this.state.prevRequest > Date.now() - 500) return;
        this.setState({ prevRequest: Date.now() })



        let urlSearchParams = {};

        // Keywords
        const keywords = values.keywords.replace(" ", ",");
        if (keywords !== '') urlSearchParams.k = keywords;
        let categories = '';

        // Categories
        for (const cat of selectedCategories) {
            if (categories !== '') categories += ',';
            categories += cat.id;
        }
        if (categories !== '') urlSearchParams.c = categories;


        // Quantity
        urlSearchParams.n = parseInt(values.quantity);


        // Type
        if (values.type !== '-1') urlSearchParams.t = parseInt(values.type);

        // Image
        if (values.image !== '-1') urlSearchParams.i = parseInt(values.image);

        const params = new URLSearchParams(urlSearchParams)
        const options = {
            method: 'GET'
        }
        const requestUrl = this.state.serverUrl + 'q?' + params;
        this.setState({ requestUrl: requestUrl });
        fetch(requestUrl, options)
            .then((response) => response.json())
            .then((json) => {
                this.setState({ results: json })
            })
            .catch(err => console.log(err));
    }

    render() {
        let results = [];
        if (this.state.results !== '') results = this.state.results;
        const Categories = this.state.categories;
        return (
            <>
                <section class="hero is-primary">
                    <div class="hero-body">
                        <Formik
                            initialValues={{
                                keywords: '', quantity: 10, type: '-1', image: '-1'
                            }}
                            onSubmit={async (values, actions) => {
                                //this.submitForm(values, actions);
                            }}>
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form className="questionForm container" enctype="multipart/form-data">



                                    {/* KEYWORDS */}
                                    <div className="field">
                                        <label for="keywords" className="label has-text-white">Keywords </label>
                                        <div className="control has-icons-left">
                                            <Field id="keywords" name="keywords" placeholder="Keywords" className={errors.keywords && touched.keywords ? 'input is-danger' : 'input'} autoFocus min="1" />
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-question"></i>
                                            </span>
                                        </div>
                                    </div>



                                    {/* SELECTED CATEGORIES */}
                                    <div className="field">
                                        {
                                            this.state.selectedCategories !== undefined ?
                                                this.state.selectedCategories.map(data => (
                                                    <span class="tag is-info mr-2">
                                                        {data.name === 'Géographie' ?
                                                            <span class="icon">
                                                                <i class="fas fa-globe-africa"></i>
                                                            </span> : null
                                                        }
                                                        {data.name === 'Histoire' ?
                                                            <span class="icon">
                                                                <i class="fas fa-landmark"></i>
                                                            </span> : null
                                                        }
                                                        {data.name === 'Sciences' ?
                                                            <span class="icon">
                                                                <i class="fas fa-flask"></i>
                                                            </span> : null
                                                        }
                                                        {data.name === 'Astronomie' ?
                                                            <span class="icon">
                                                                <i class="fas fa-moon"></i>
                                                            </span> : null
                                                        }
                                                        {data.name}
                                                        <button class="delete is-small ml-2" onClick={() => { this.deleteCategory(data.id) }}></button>
                                                    </span>
                                                )) : null
                                        }
                                    </div>
                                    <div className="field is-horizontal">

                                        {/* CATEGORIE */}
                                        <label for="category" className="label mr-3 has-text-white">Catégorie(s)</label>
                                        <div className="field has-addons">
                                            <div className="control has-icons-left" >
                                                <input
                                                    type="text"
                                                    name="category"
                                                    list="categoryList"
                                                    id="category"
                                                    className="input is-small"
                                                    autocomplete="off"
                                                />
                                                <datalist id="categoryList">
                                                    {
                                                        Categories !== undefined ?
                                                            Categories.map(data => (
                                                                <option value={data.name}>{data.name}</option>
                                                            )) : null
                                                    }
                                                </datalist>
                                                <span class="icon is-small is-left">
                                                    <i class="far fa-file-alt"></i>
                                                </span>
                                            </div>
                                            <div className="control">
                                                <span className="button is-info is-small"
                                                    onClick={() => this.addCategory(values)}
                                                >Ajouter</span>
                                            </div>
                                        </div>
                                        {this.state.errors && this.state.errors.category ? <span className="help is-danger">Vous devez renseigner au moins une catégorie.</span> : null}

                                        <label for="quantity" className="label mr-3 ml-5 has-text-white">Quantity</label>
                                        <div className="field has-addons">
                                            <div className="control has-icons-left" >
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    id="quantity"
                                                    value={values.quantity}
                                                    step="1"
                                                    min="0"
                                                    onChange={e => {
                                                        e.preventDefault();
                                                        const { value } = e.target;
                                                        const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                        if (regex.test(value.toString())) {
                                                            setFieldValue("quantity", value);
                                                        }
                                                    }}
                                                    className="input is-small"
                                                />
                                            </div>
                                        </div>



                                    </div>




                                    <div className="field is-horizontal">
                                        {/* TYPE */}
                                        <label for="type" className="label mr-3 has-text-white">Type</label>
                                        <div className="field-body">

                                            <div role="group" className="control">
                                                <label className="">
                                                    <Field type="radio" name="type" value="-1" className="is-hidden"
                                                        checked={values.type === '-1' ? 'checked' : null} />
                                                    <span class={values.type === '-1' ? 'button is-info is-small mr-2 has-text-weight-bold' : 'button is-light is-small mr-2 is-italic'}>Tout</span>
                                                </label>
                                                <label className="">
                                                    <Field type="radio" name="type" value="0" className="is-hidden"
                                                        checked={values.type === '0' ? 'checked' : null} />
                                                    <span class={values.type === '0' ? 'button is-info is-small mr-2 has-text-weight-bold' : 'button is-light is-small mr-2 is-italic'}>Choix Multiple</span>
                                                </label>
                                                <label className="">
                                                    <Field type="radio" name="type" value="1" className="is-hidden" />
                                                    <span class={values.type === '1' ? 'button is-info is-small has-text-weight-bold' : 'button is-light is-small is-italic'} >Vrai / Faux</span>

                                                </label>
                                            </div>


                                            {/* IMAGE */}
                                            <label for="image" className="label mr-3 ml-5 has-text-white">Image</label>
                                            <div role="group" className="control">
                                                <label className="">
                                                    <Field type="radio" name="image" value="-1" className="is-hidden"
                                                        checked={values.image === '-1' ? 'checked' : null} />
                                                    <span class={values.image === '-1' ? 'button is-info is-small mr-2 has-text-weight-bold' : 'button is-light is-small mr-2 is-italic'}>Avec ou sans</span>
                                                </label>
                                                <label className="">
                                                    <Field type="radio" name="image" value="0" className="is-hidden"
                                                        checked={values.image === '0' ? 'checked' : null} />
                                                    <span class={values.image === '0' ? 'button is-info is-small mr-2 has-text-weight-bold' : 'button is-light is-small mr-2 is-italic'}>Sans</span>
                                                </label>
                                                <label className="">
                                                    <Field type="radio" name="image" value="1" className="is-hidden" />
                                                    <span class={values.image === '1' ? 'button is-info is-small has-text-weight-bold' : 'button is-light is-small is-italic'} >Avec</span>

                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* REQUEST */}
                                    <div className="field">
                                        <label for="request" className="label has-text-white">Keywords </label>
                                        <div className="control has-icons-left">
                                            <input id="request" name="request" readonly placeholder="Keywords" value={this.state.requestUrl} className="input" min="1" />
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-question"></i>
                                            </span>
                                        </div>
                                    </div>

                                    {/* 

                                <button type="submit" className={this.state.status === 'submitting' ? 'button is-primary is-loading' : 'button is-primary'}>Envoyer</button> */}
                                    {this.requestApi(values, this.state.selectedCategories)}
                                </Form>

                            )

                            }
                        </Formik>

                    </div></section>
                {results.length > 0 ?
                    results.map(data => (
                        <QuestionBox data={data} serverUrl={this.state.serverUrl} />
                    ))
                    : <section>'Aucun résultat'</section>}
            </>
        )
    }
}

export default App;
